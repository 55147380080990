<script setup>
import { useStorage } from '@vueuse/core'

const echo = useLaravelEcho()

const prescriptions = useStorage('notificationPrescriptions', [])

echo.private(`employees.${user().id}`).listen('AddPrescriptionEvent', (e) => {
	const foundPrescription = prescriptions.value.find((item) => item.id == e.prescription.id)

	if (foundPrescription) return

	prescriptions.value.push({
		id: e.prescription.id,
		title: `${e.prescription.object.title} - ${e.prescription.title}`,
		subtitle: e.prescription.resolve_before,
		to: '/prescriptions/' + e.prescription.id,
	})
})
</script>

<template>
	<Notification
		icon="tabler-ad-2"
		title="prescription.notifications"
		v-model="prescriptions"
		@removeAll="prescriptions = []" />
</template>
